<!-- eslint-disable -->

<template>
  <div class="z-[14] let swipeIn">
    <div
      class="w-screen h-screen sm:w-[500px] rounded-md bg-white pt-4 pb-8 overflow-y-auto"
    >
      <div class="px-4 mt-4">
        <div class="flex items-center space-x-2 mb-4">
          <button @click="handleClose">
            <img src="@/assets/Svg/arrowleft.svg" alt="" />
          </button>
          <h1 class="text-[#00253B] font-medium">Choose bank</h1>
        </div>
        <div class="bg-[#E6F5FE] p-2 flex space-x-2 rounded-lg">
          <img src="@/assets/Svg/searchbank.svg" alt="" />
          <input
            type="text"
            class="bg-transparent outline-none border-none flex-1"
            placeholder="Search"
            @input="getSearchParam($event)"
          />
        </div>
      </div>

      <div class="bg-white pt-6 pb-8 overflow-auto">
        <div v-for="(objects, key) in sortedBanks" :key="key">
          <div class="bg-[#E6F5FE] px-6 p-3">
            <h2 class="font-bold">{{ key }}</h2>
          </div>
          <ul>
            <li
              v-for="(obj, index) in objects"
              :key="index"
              class="px-4 py-2 border-b flex space-x-2 items-center hover:bg-blue-200"
              @click="handleBankSelect(obj)"
            >
              <span class="p-2 rounded-full mr-4 bg-[#D9D9D9]">
                <img src="@/assets/Svg/bank.svg" alt="" />
              </span>
              {{ obj.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
export default {
  name: "BankList",
  props: ["banks", "handleClose", "handleBankSelect"],
  data() {
    return {
      searchParam: "",
    };
  },

  computed: {
    sortedBanks() {
      const filterBanks = this.banks.filter((bank) =>
        bank.name.toLowerCase().includes(this.searchParam.toLowerCase())
      );

      const sortedResult = filterBanks.reduce((result, obj) => {
        const firstLetter = obj.name[0].toUpperCase();
        if (!result[firstLetter]) {
          result[firstLetter] = [];
        }
        result[firstLetter].push(obj);
        return result;
      }, {});

      const keys = Object.keys(sortedResult).sort();
      const numericObjects = sortedResult["#"] || [];
      delete sortedResult["#"];
      keys.unshift(...numericObjects.map(() => "#"));

      return keys.reduce((result, key) => {
        result[key] = sortedResult[key];
        return result;
      }, {});
    },
  },
  methods: {
    getSearchParam(e) {
      this.searchParam = e.target.value;
    },
  },
};
</script>
