<!-- eslint-disable -->

<template>
  <div class="w-full py-16 px-4 flex flex-col justify-center items-center">
    <div
      style="
        background-image: url('/Receipt.png');
        background-size: contain;
        background-repeat: no-repeat;
        height: 1000px;
      "
      class="p-4 w-[100vw] sm:w-[400px]"
    >
      <div class="pt-12 md:pt-14 text-center">
        <p class="text-3xl font-medium">
          {{ txdata?.amount_formatted_disp || "loading..." }}
        </p>
        <p class="text-xs">
          Middey -
          {{ currentUserData.account_no_ng_default.substring(0, 3) }}xxxxxx{{
            currentUserData.account_no_ng_default.substring(7, 11)
          }}
        </p>
      </div>

      <div class="text-center mt-14 md:mt-14 font-semibold">
        Transaction Reciept
      </div>

      <div class="pt-[20px]">
        <div class="flex flex-col gap-5 font-medium">
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Transaction Date:</p>
            </div>
            <div class="col-span-6 text-[12px] md:text-sm flex justify-end">
              {{ getDate("LLL", txdata?.post_date) || "loading.." }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Transaction Ref:</p>
            </div>
            <div class="col-span-6 text-xs text-right">
              ID_{{ txdata?.transaction_id || "loading..." }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Transaction Type:</p>
            </div>
            <div
              class="col-span-6 text-sm flex justify-end"
              :class="
                txdata?.txn_type === 'DEBIT' ? 'text-red-500' : 'text-green-500'
              "
            >
              {{ txdata?.txn_type || "loading..." }}
            </div>
          </div>

          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Credit Account:</p>
            </div>
            <div class="col-span-6 text-[12px] truncate flex justify-end">
              {{ receiverAcctNumber }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Receiver Name:</p>
            </div>
            <div class="col-span-6 text-[12px] truncate flex justify-end">
              {{ receiverName }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Receiver Bank:</p>
            </div>
            <div class="col-span-6 text-[12px] truncate flex justify-end">
              {{ receiverBankName }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Narration:</p>
            </div>
            <div class="col-span-6 text-xs md:text-sm truncate overflow-x-auto">
              {{ txdata?.note || "loading..." }}
            </div>
          </div>

          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Status:</p>
            </div>
            <div class="col-span-6 text-sm flex justify-end">
              {{ txdata?.txn_type === "DEBIT" ? status : "Successful" }}
            </div>
          </div>
          <div class="md:mt-[170px] mt-48">
            <div class="col-span-6 text-sm ">
              {{ txdata?.txn_type === "DEBIT" ? message : "The transaction was sucessful" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="print"
      v-if="!webview"
      class="bg-blue-400 text-white rounded p-4 -mt-24"
    >
      Download
    </button>

    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="900"
        filename="Middey Reciept"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="A6"
        pdf-orientation="portrait"
        pdf-content-width="400px"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <!-- PDF Content Here -->
        <Print slot="pdf-content" :result="result" />
      </vue-html2pdf>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
import VueHtml2pdf from "vue-html2pdf";
import Print from "./Print.vue";
import { digitFormatter } from "@/Utils/helper_function";
import axios from "@/Utils/axios.config.js";
import axiosInstance from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ResultCmp",
  data() {
    return {
      webview: false,
      loading: false,
      txdata: null,
      receiverName: "",
      receiverAcctNumber: "",
      receiverBankName: "",
      status: "",
      message: "",
    };
  },

  components: {
    Print,
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters(["currentUserData", "currentUser"]),
    result() {
      return {
        txdata: this.txdata,
        receiverName: this.receiverName,
        receiverAcctNumber: this.receiverAcctNumber,
        receiverBankName: this.receiverBankName,
        status: this.status,
        message: this.message,
      };
    },
    txId() {
      return +this.$route.params.id;
    },
  },
  mounted() {
    this.getTxDetails();
    console.log(this.txId);
    let standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (!standalone && safari) {
        // Safari
      } else if (!standalone && !safari) {
        // iOS webview
        this.webview = true;
      }
    } else {
      if (userAgent.includes("wv")) {
        this.webview = true;
      } else {
        // Chrome
      }
    }
  },
  methods: {
    digitFormatter(input) {
      return digitFormatter(input);
    },
    getDate(Format, date) {
      return moment(date).format(Format);
    },
    print() {
      // Pass the element id here
      this.$refs.html2Pdf.generatePdf();
    },
    getTxDetails() {
      // wp-json/nellalink/v1/smart-meta-manager
      try {
        this.loading = true;
        axios.get(`/transactions/?transaction_id=${+this.txId}`).then((res) => {
          this.$toast.success("Transaction Retrieved Succesfully");
          this.loading = false;
          this.txdata = res.data;
          console.log(res.data);
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
      axiosInstance
        .get(
          `https://backend.middey.com/wp-json/nellalink/v1/smart-meta-manager?entity_type=post&entity_id=${+this
            .txId}&meta_key=transaction_verification_account_number`
        )
        .then((res) => {
          console.log(res.data);
          this.receiverAcctNumber = res.data.data[0];
        })
        .catch((error) => {
          this.receiverAcctNumber = "xxxxxxxx";

          console.log(error);
        });
      axiosInstance
        .get(
          `https://backend.middey.com/wp-json/nellalink/v1/smart-meta-manager?entity_type=post&entity_id=${+this
            .txId}&meta_key=transaction_verification_bank_name`
        )
        .then((res) => {
          console.log(res.data);
          this.receiverBankName = res.data.data[0];
        })
        .catch((error) => {
          this.receiverBankName = "xxxxxxxx";

          console.log(error);
        });
      axiosInstance
        .get(
          `https://backend.middey.com/wp-json/nellalink/v1/smart-meta-manager?entity_type=post&entity_id=${+this
            .txId}&meta_key=transaction_verification_recipient_fullname`
        )
        .then((res) => {
          this.receiverName = res.data.data[0];
          console.log(res.data);
        })
        .catch((error) => {
          this.receiverName = "xxxxxxxx";

          console.log(error);
        });
      axiosInstance
        .get(
          `https://backend.middey.com/wp-json/nellalink/v1/smart-meta-manager?entity_type=post&entity_id=${+this
            .txId}&meta_key=transaction_verification_status`
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.data[0] == "success") {
            this.status = "Successful";
            this.message =
              "We've successfully sent this transaction to the recipients bank.";
          } else {
            this.status = "Scheduled for Reversal";
            this.message =
              "This transaction has been scheduled for Reversal within the next 48 hours";
          }
        })
        .catch((error) => {
          console.log(error);
          this.status = "Processed";
          this.message =
            "This transaction has been broadcasted to the recipients bank, we are awaiting updates from them.";
        });
    },
  },
};
</script>
<!-- eslint-disable -->

<style scoped>
.topMargin {
  padding-top: 130px;
}
.padding {
  padding-top: 20px;
}
.padding2 {
  padding-top: 40px;
  color: red;
}
</style>
