<!-- eslint-disable -->
<template>
  <div>
    <div class="rounded-md border-2 border-dashed p-4 w-full">
      <img
        v-if="passport !== null"
        :src="passport"
        class="h-[300px] w-full"
        alt=""
      />
      <div v-else class="flex flex-col justify-center items-center space-y-5">
        <div>
          <img src="@/assets/Svg/camera.svg" alt="" />
        </div>
        <h4 class="font-medium text-xs text-[#201E1E80]">Passport</h4>

        <button
          @click="openUploadModal"
          class="border border-blue-500 rounded cursor-pointer py-2 px-4"
        >
          <span v-if="loading"> Loading...</span>
          <span v-else> Take a shot</span>
        </button>
      </div>
    </div>
    <button
      v-if="passport"
      type="button"
      class="w-full mt-4 px-2 py-3 rounded border border-red-500 hover:bg-red-500 transform duration-300 ease-linear hover:text-white"
      @click="passport = null"
    >
      <div v-if="loading">Uploading...</div>
      <div v-else>Re-take</div>
    </button>

    <!-- <button
      @click="updateStatus"
      :disabled="update"
      :class="update ? 'cursor-not-allowed' : null"
      class="text-white bg-[#ed3236] rounded-3xl w-full py-2 mt-4"
    >
      <span v-if="update">Updating</span>
      <span v-else> Update status</span>
    </button> -->
  </div>
</template>
<!-- eslint-disable -->

<script>
/*eslint-disable*/
import * as filestack from "filestack-js";
export default {
  name: "UploadInmage",
  props: ["passport", "handlePassport"],

  data() {
    return {
      imageUpdate: null,
      picture: null,
      loading: false,
    };
  },

  methods: {
    openUploadModal() {
      this.loading = true;
console.log(process.env.VUE_APP_CLOUDINARY_CLOUD_NAME);
      window.cloudinary
        .openUploadWidget(
          {
            cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
            upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET,
            folder: process.env.VUE_APP_CLOUDINARY_STORAGE_FOLDER,
            maxImageFileSize: 2000000,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.loading = false;
              this.$toast.success("Passport Taken successfully");
              this.handlePassport(result.info.url);
            }
          }
        )
        .open();
    },
    async handleImageUpload(event) {
      this.imageUpdate = event.target.files[0];
      this.picture = URL.createObjectURL(this.imageUpdate);
      const formData = new FormData();
      formData.append("image", this.imageUpdate);
      let fileObject = new File([this.imageUpdate], "image.png", {
        type: "image/jpeg",
      });
      this.loading = true;

      const client = filestack.init(this.filestackApiKey);
      await client.upload(fileObject).then((data) => {
        console.log(data.url);
        this.loading = false;
        this.$toast.success("Passport Taken successfully");
        this.handlePassport(data.url);
      });
    },
  },
};
</script>

<style></style>
