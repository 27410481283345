<!-- eslint-disable -->
<template>
  <div class="w-full py-16 px-4">
    <h1 class="md:text-3xl text-xl">
      Kindly Click on the button in the bottom right corner of the page to start a chat
      with our support staff. Or contact us on <a class="text-blue-500" href="mailto:hello@middey.com">hello@middey.com</a>
    </h1>
  </div>
</template>
<script>
/*eslint-disable*/
export default {
  mounted() {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/62d90cf437898912e95ee3be/1g8ftl85m";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  },
};
</script>
