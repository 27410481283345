var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex min-h-screen"},[_c('div',{staticClass:"md:ml-[300px] px-4 py-3 bg-white text-[#000000] fixed top-0 inset-x-0 flex justify-between items-center z-[9]"},[_c('div',{staticClass:"font-bold capitalize flex gap-4 items-center"},[_c('button',{staticClass:"md:hidden block",on:{"click":_vm.toggle}},[_c('i',{staticClass:"fa-solid text-xl fa-bars"})]),_vm._m(0)]),_c('div',{staticClass:"space-x-6 flex items-center"},[_vm._m(1),_c('button',{staticClass:""},[_c('router-link',{attrs:{"to":"/settings"}},[_c('img',{staticClass:"object-fit md:h-10 h-8 md:w-10 w-8",attrs:{"src":require("@/assets/Svg/user.svg")}})])],1)])]),(_vm.isMobile)?_c('div',{staticClass:"inset-0 bg-[#00000050] z-[3] absolute",on:{"click":_vm.toggle}}):_vm._e(),_c('div',{staticClass:"sidebar transform duration-500 md:w-[300px] w-[280px] z-10 h-screen md:flex flex-col justify-between bg-white pb-10",class:[_vm.isMobile ? 'isOpen' : null, 'sidebar']},[_c('div',[_c('div',{staticClass:"grid place-content-center mx-auto uppercase text-white font-bold text-xl bg-[#0192ED] rounded-full h-28 w-28 mt-4 mb-3"},[_vm._v(" "+_vm._s(_vm.currentUserData.first_name.charAt(0))+_vm._s(_vm.currentUserData.last_name.charAt(0))+" ")]),_c('h2',{staticClass:"font-medium mb-6 text-center"},[_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.currentUserData.first_name)+" "+_vm._s(_vm.currentUserData.last_name)+" ")])]),_c('div',{staticClass:"mt-4 px-5 space-y-2",on:{"click":function($event){_vm.isMobile = false}}},[_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('dashboard')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"/dashboard"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-home text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v("Home")])]),_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('kyc')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"/kyc"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-file text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v("KYC/Compliance")])]),_c('div',{staticClass:"border-b-2 border-gray-300 -translate-x-[20px] w-[300px] transform"}),_c('div',{staticClass:"mt-8"},[_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 mt-6 py-2",class:_vm.isActiveRoute('transaction')
                ? 'bg-[#0192ED90] text-[#ffffffdb]'
                : 'text-[#515352]',attrs:{"to":"transaction"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-exchange text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v("Transactions")])])],1),_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('employee')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"#"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-users text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v(" Employees "),_c('small',{staticClass:"text-[10px] text-gray-600"},[_vm._v("(Coming Soon)")])])]),_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('payroll')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"#"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-credit-card text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v(" Payroll "),_c('small',{staticClass:"text-[10px] text-gray-600"},[_vm._v("(Coming Soon)")])])]),_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('swipetopay')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"/swipetopay"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-money-check-dollar text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v("Pay Merchant")])]),_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('utility')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"/utility"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-money-bill text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v("Bill Payment")])]),_c('router-link',{staticClass:"flex items-center hover:bg-[#0192ED90] hover:text-[#ffffffdb] transition-all transform duration-500 font-medium rounded-md px-5 py-2",class:_vm.isActiveRoute('settings')
              ? 'bg-[#0192ED90] text-[#ffffffdb]'
              : 'text-[#515352]',attrs:{"to":"/settings"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{class:`fa-solid fa-cog text-base`})]),_c('div',{staticClass:"w-10/12"},[_vm._v("Settings")])])],1)]),_c('div',{staticClass:"border-y-2 py-2 border-gray-300"},[_c('div',{staticClass:"flex items-center transform duration-500 transition-all font-medium text-base rounded-md hover:bg-[#0192ED90] hover:text-[#ffffffdb] mx-5 px-5 py-2 mt-2 cursor-pointer"},[_vm._m(2),_c('div',{staticClass:"w-10/12 flex justify-between items-center",on:{"click":_vm.toggleIntegration}},[_c('div',[_vm._v("Integrations")]),_vm._m(3)])]),(_vm.isIntegrationActive)?_c('div',{staticClass:"ml-20 flex flex-col text-xs md:text-sm font-medium space-y-2"},[_vm._m(4),_vm._m(5)]):_vm._e()]),_c('div',{},[_vm._m(6),_c('div',{staticClass:"flex items-center transform duration-500 transition-all font-medium text-base rounded-md hover:bg-[#0192ED90] hover:text-[#ffffffdb] mx-5 px-5 mt-1 py-2 cursor-pointer",on:{"click":_vm.logout}},[_vm._m(7),_c('div',{staticClass:"w-10/12"},[_vm._v("Logout")])])])]),_c('div',{staticClass:"main h-screen"},[_c('router-view')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"font-bold text-[#0192ED]",attrs:{"href":"/dashboard"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/Middey_Horizontal.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:""},[_c('i',{staticClass:"fa-solid text-xl fa-bell"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-2/12"},[_c('i',{staticClass:"fa-solid fa-network-wired"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa-solid fa-angle-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex space-x-2"},[_c('div',[_c('i',{staticClass:"fa-solid fa-angles-right"})]),_c('div',[_vm._v("Become Middey Vendor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex space-x-2"},[_c('div',[_c('i',{staticClass:"fa-solid fa-angles-right"})]),_c('div',[_vm._v("Sign up as Nellalink Merchant")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"flex items-center transform duration-500 transition-all font-medium text-base rounded-md hover:bg-[#0192ED90] hover:text-[#ffffffdb] mx-5 px-5 mt-10 py-2 cursor-pointer",attrs:{"href":"/support"}},[_c('span',{staticClass:"w-2/12"},[_c('i',{staticClass:"fa-solid fa-envelope"})]),_c('div',{staticClass:"w-10/12"},[_vm._v("Contact Support")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"w-2/12"},[_c('i',{staticClass:"fa-solid fa-arrow-right-from-bracket"})])
}]

export { render, staticRenderFns }