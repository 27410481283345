<!-- eslint-disable -->
<template>
  <div class="z-[12] let swipeIn">
    <div class="px-4">
      <div class="mx-auto flex flex-col justify-center items-center space-y-3">
        <div class="animate animate-pulse">
          <img src="@/assets/Svg/successtransfer.svg" alt="" />
        </div>
        <h2 class="text-2xl">Successful</h2>
        <h2 class="text-2xl font-bold">
          ₦ {{ digitFormatter(formatedAmount) }}
        </h2>
      </div>
      <div class="info_box md:p-6 p-4 mt-6">
        <div class="grid grid-cols-2 gap-6 text-xs">
          <div class="text-right">Recipient Name</div>
          <div class="font-bold truncate">{{ account_name }}</div>
          <div class="text-right">Transaction Type</div>
          <div class="font-bold">Debit</div>
          <div class="text-right">Bank Name</div>
          <div class="font-bold">{{ bank_name }}</div>
          <div class="text-right">Receiver Acct No </div>
          <div class="font-bold">{{ account_no }}</div>
          <div class="text-right">Transaction Date</div>
          <div class="font-bold">{{ dateFormatter(Date.now()) }}</div>
          <div class="text-right">Payment Amount</div>
          <div class="font-bold">₦ {{ digitFormatter(formatedAmount) }}</div>
          <div class="text-right">Narration</div>
          <div class="font-bold">{{ narration || "Null" }}</div>
        </div>
      </div>

      <div class="my-6">
        <router-link
          to="/transaction"
          class="bg-primary-100 flex justify-center items-center text-white md:text-base text-sm rounded py-3 px-6 w-full space-x-2"
        >
          <img src="@/assets/Svg/document.svg" alt="" />
          <div class="font-medium">VIEW RECEIPT</div>
        </router-link>
      </div>

      <div class="flex justify-center">
        <button @click="reload" class="underline text-primary-100">
          Go to Home
        </button>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
import { digitFormatter } from "@/Utils/helper_function/";
import moment from "moment";
export default {
  name: "SuccesfulTransfer",
  props: ["amount", "account_name", "bank_name", "narration", "account_no", "formatedAmount"],
  methods: {
    digitFormatter(balance) {
      return digitFormatter(balance);
    },
    dateFormatter(date) {
      return moment(date).format("LLL");
    },

    reload() {
      location.reload();
    },
  },
};
</script>
<style scoped>
.info_box {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
</style>
