<!-- eslint-disable -->
<template>
    <div class="w-full py-16 px-4">
        <Swipetopay />
    </div>
</template>
<!-- eslint-disable -->

<script>
import Swipetopay from '../../components/swipetopay.vue';
export default {
    components: { Swipetopay }
}
</script>

<style>

</style>