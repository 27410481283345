<!-- eslint-disable -->
<template>
  <div class="z-[12] let swipeIn">
    <div class="px-4">
      <div class="mx-auto flex flex-col justify-center items-center text-center space-y-3">
        <div class="animate animate-pulse">
          <img src="@/assets/Svg/transfererror.svg" alt="" />
        </div>
        <h2 class="text-2xl">Error</h2>
        <h2 class="text-xl font-medium">{{ errorMessage }}</h2>
      </div>

      <div class="my-6">
        <button
          @click="reload"
          class="border border-primary-100 text-primary-100 flex justify-center items-center md:text-base text-sm rounded py-3 px-6 w-full space-x-2"
        >
          <div class="font-medium">MAKE ANOTHER TRANSACTION</div>
        </button>
      </div>

      <div class="flex justify-center">
        <button @click="reload" class="underline text-primary-100">
          Go to Home
        </button>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
export default {
  name: "ErrorTransfer",
  props: ["errorMessage"],
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>
<style scoped>
.info_box {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
</style>
