<!-- eslint-disable -->

<template>
  <div class="bg-white w-full pt-16 pb-4 min-h-screen">
    <div class=" ">
      <div class="md:w-2/3 pt-4 pb-8">
        <div>
          <button
            class="flex items-center space-x-2 mb-4 px-4"
            @click="$router.go(-1)"
          >
            <img src="@/assets/Svg/arrowleft.svg" class="w-4 h-4" alt="" />
            <h1 class="text-[#00253B] font-medium">Go Back</h1>
          </button>
          <div class="bg-[#E6F5FE] font-bold p-3 mb-3 flex justify-between">
            <h1>
              Balance:
              <span class="" v-if="showBalance">
                {{ acct_balance }}
              </span>
              <span class="" v-else>₦XXXX</span>
            </h1>

            <button
              class="text-blue-500 px-2 text-base md:text-xl font-medium icon cursor-pointer"
              @click="showBalance = !showBalance"
            >
              <span v-if="showBalance"><i class="fas fa-eye-slash"></i></span>
              <span v-else><i class="fas fa-eye"></i></span>
            </button>
          </div>
          <div
            class="border-y border-black py-4 my-5 grid grid-cols-12 gap-4 items-center"
          >
            <div class="col-span-5 px-2">
              <div
                class="p-2 bg-white relative rounded-lg border md:text-base text-[10px]"
              >
                <div
                  class="flex space-x-2 items-center justify-between cursor-pointer"
                  @click="isDropdown = !isDropdown"
                >
                  <span v-if="!selectedNetwork">Select Network</span>
                  <div v-else class="flex space-x-2 items-center w-full">
                    <span> {{ selectedNetwork.name }} </span>
                    <img
                      :src="selectedNetwork.image"
                      alt=""
                      class="h-4 w-4 object-contain rounded-full"
                    />
                  </div>
                  <img src="@/assets/Svg/Utility/dropdown.svg" alt="" />
                </div>

                <div
                  class="absolute z-[1000] rounded-b-lg border bg-white shadow-lg left-0 top-9 w-full"
                  v-if="isDropdown"
                >
                  <button
                    v-for="(item, idx) in networks"
                    :key="idx"
                    class="flex space-x-2 items-center p-3 hover:bg-slate-100 w-full"
                    @click="handleNetworkSelection(item)"
                  >
                    <span> {{ item.name }} </span>
                    <img
                      :src="item.image"
                      alt=""
                      class="h-4 w-4 object-contain rounded-full"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-span-5">
              <input
                type="number"
                class="outline-none border-b border-blue-400 bg-transparent p-3"
                placeholder="0816 574 2345"
                v-model="phone"
              />
            </div>
            <div class="col-span-2">
              <img src="@/assets/Svg/Utility/contact.svg" alt="" />
            </div>
          </div>
          <div class="p-4">
            <h1 class="font-medium my-2">Select Data Plan</h1>
            <ActionLoader v-if="dataloading" />
            <div v-else v-for="(item, key) in dataListOutput" :key="key">
              <div
                class="card_price items-center my-3 border-l-[20px] border-[#0092EC] rounded-lg grid grid-cols-2 gap-4 p-4"
              >
                <div class="space-y-4">
                  <h1 class="font-medium">{{ item.name }}</h1>
                  <p class="">₦{{ item.price }}</p>
                </div>
                <div>
                  <button
                    @click="handleDataPurchase(item)"
                    class="text-white p-3 rounded-lg w-full"
                    :disabled="isDataError || item.price == 0"
                    :class="
                      isDataError || item.price == 0
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-500'
                    "
                  >
                    <ActionLoader
                      v-if="
                        datapurchaseloading &&
                        chooseDataPlan_id == item.variation_id
                      "
                    />
                    <span v-else> ₦{{ item.price }} </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="fixed z-[10] bg-black/30 inset-0 w-full grid place-content-center p-2"
          v-if="isConfirmed.airtime || isConfirmed.data"
        >
          <div class="bg-white rounded-lg p-3 shadow w-[350px]">
            <div class="flex justify-end">
              <button @click="closeModal" class="">
                <img src="@/assets/Svg/close.svg" alt="" />
              </button>
            </div>
            <h1 class="text-center font-semibold">Confirm Details</h1>

            <div class="grid grid-cols-2 gap-4 mt-6 mb-2">
              <div>Mobile number</div>
              <div class="font-medium text-right">{{ phone }}</div>
            </div>

            <div class="grid grid-cols-2 gap-4 my-2">
              <div>Provider</div>
              <div class="font-medium text-right uppercase">
                {{ network_id }}
              </div>
            </div>

            <div class="grid grid-cols-2 gap-4 my-2">
              <div>Service</div>
              <div class="font-medium text-right">Data</div>
            </div>

            <div class="grid grid-cols-2 gap-4 my-2">
              <div>Amount</div>
              <div class="font-medium text-right">
                ₦{{ this.dataDetails.price }}
              </div>
            </div>

            <div class="space-y-5 my-8">
              <button
                @click="handleTopUpPurchase"
                class="text-white p-3 rounded-lg w-full bg-blue-500"
              >
                YES
              </button>
              <button
                @click="closeModal"
                class="text-red-500 p-3 rounded-lg w-full border border-red-500"
              >
                NO, CANCEL
              </button>
            </div>
          </div>
        </div>
        <div
          class="fixed inset-0 z-[9]"
          v-if="isDropdown"
          @click="isDropdown = !isDropdown"
        ></div>

        <SuccessAirtime
          v-if="step === 3"
          :amount="amount"
          :formatedAmount="amount"
          :phonenumber="phone"
          :narration="narration"
        />
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import axios from "axios";
import numeral from "numeral";
import { mapGetters } from "vuex";
import ActionLoader from "@/components/ActionLoader.vue";
import SuccessAirtime from "@/components/SucessAirtime.vue";

export default {
  components: { ActionLoader, SuccessAirtime },
  name: "BankList",
  props: [],
  data() {
    return {
      searchParam: "",
      isDropdown: false,
      selectedNetwork: null,
      dataDetails: null,
      networks: [
        {
          network_id: "mtn",
          name: "MTN",
          image:
            "https://i.pinimg.com/474x/ca/72/9b/ca729b8adbaa8e96f99f1cd0d8a64a8c.jpg",
        },
        {
          network_id: "glo",
          name: "GLO",
          image:
            "https://www.styzic.com/wp-content/uploads/2017/08/GLO-NIGERIA.jpg",
        },
        {
          network_id: "airtel",
          name: "Airtel",
          image:
            "https://thewhistler.ng/wp-content/uploads/2019/08/Airtel-logo-1.jpg",
        },
        {
          network_id: "etisalat",
          name: "Etisalat",
          image:
            "https://www.seekpng.com/png/detail/344-3443327_9mobile-mtn-glo-airtel-and-9mobile.png",
        },
      ],
      electtricityBills: [
        {
          name: "AEDC",
          logo: "aedc",
        },
        {
          name: "EKEDC",
          logo: "ekedc",
        },
        {
          name: "IBEDC",
          logo: "ibedc",
        },
        {
          name: "IKEDC",
          logo: "ikedc",
        },
        {
          name: "JED",
          logo: "jed",
        },
        {
          name: "KAEDCO",
          logo: "kaedco",
        },
        {
          name: "KEDCO",
          logo: "kedco",
        },
        {
          name: "PHED",
          logo: "phed",
        },
      ],
      cable: [
        {
          name: "DSTV",
          logo: "dstv",
        },
        {
          name: "Go TV",
          logo: "gotv",
        },
        {
          name: "Star Times",
          logo: "startimes",
        },
      ],

      step: 0,
      showBalance: false,
      option: "data",
      dataList: [],
      variation_id: "",
      network_id: "",
      phone: "",
      amount: 0,
      acct_balance: 0,
      loading: false,
      isConfirmed: {
        airtime: false,
        data: false,
      },
      airtimeSuccess: false,
      dataloading: false,
      airtimeloading: false,
      datapurchaseloading: false,
      narration: "",
      confirm_narration: "",
      isError: false,
    };
  },

  computed: {
    ...mapGetters(["currentUserData", "currentUser"]),
    isAirtimeError() {
      if (this.phone.length < 11 || this.amount == 0 || this.network_id == "") {
        return true;
      } else return false;
    },
    isDataError() {
      if (this.phone.length < 11 || this.network_id == "") {
        return true;
      } else return false;
    },

    dataListOutput() {
      if (this.network_id === "mtn") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("mtn")
        );
      }
      if (this.network_id === "glo") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("glo")
        );
      }
      if (this.network_id === "airtel") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("airtel")
        );
      }
      if (this.network_id === "etisalat") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("etisalat")
        );
      }
    },
  },
  mounted() {
    this.getBalance();
    this.getAirtimeList();
    this.getDataList();
  },
  methods: {
    closeModal() {
      this.isConfirmed.data = false;
      this.isConfirmed.airtime = false;
    },
    openModal(tab) {
      if (tab == "data") {
        this.isConfirmed.data = true;
        this.confirm_narration = `${this.phone}/${this.dataDetails.name}/`;
      } else {
        this.isConfirmed.airtime = true;
        this.confirm_narration = `${this.phone}/${this.amount}/${this.network_id}`;
      }
    },
    handleDataPurchase(item) {
      this.dataDetails = item;
      this.openModal("data");
    },
    handleTopUpPurchase() {
      if (this.isConfirmed.data) {
        this.buyData();
      } else {
        this.buyAirtime();
      }
    },
    handleNetworkSelection(item) {
      this.selectedNetwork = item;
      this.network_id = item.network_id;
      this.isDropdown = !this.isDropdown;
    },
    getSearchParam(e) {
      this.searchParam = e.target.value;
    },
    getBalance() {
      this.loading = true;
      axios
        .get(
          `https://backend.middey.com/wp-json/rimplenet/v1/user-wallet-balance?user_id=${this.currentUserData.id}&wallet_id=ngn`
        )
        .then((response) => {
          this.acct_balance = response.data.data.wallet_balance_formatted;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getAirtimeList() {
      this.loading = true;
      axios
        .get(`https://vendor.middey.com/api/airtime`)
        .then((response) => {
          console.log(response);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    buyAirtime() {
      if (this.isAirtimeError) {
        this.$toast.error("Incomplete Form");
        return;
      }
      if (numeral(this.acct_balance)._value < this.amount) {
        this.$toast.error("Insufficient balance");
        return;
      }
      this.isConfirmed.airtime = false;
      this.airtimeloading = true;
      const payload = {
        phone: this.phone,
        amount: this.amount,
        network_id: this.network_id,
        user_id: this.currentUserData.id,
        request_id: Date.now(),
        note: `${this.phone}/${this.network_id} Airtime Purchase`,
      };
      this.narration = `${this.phone}/${this.network_id} Airtime Purchase`;

      axios
        .post(`https://vendor.middey.com/api/airtime`, payload, {
          headers: {
            Authorization: `Bearer ${this.currentUser.access_token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.airtimeloading = false;
          this.airtimeSuccess = true;
          this.step = 3;
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
          this.airtimeloading = false;
        });
    },
    getDataList() {
      this.dataloading = true;
      axios
        .get(`https://vendor.middey.com/api/data`)
        .then((response) => {
          console.log(response);
          this.dataList = response.data.data;
          this.dataloading = false;
        })
        .catch((err) => {
          this.dataloading = false;
        });
    },
    buyData() {
      if (numeral(this.acct_balance)._value < this.dataDetails.price) {
        this.$toast.error("Insufficient balance");
        return;
      }
      this.isConfirmed.data = false;
      this.chooseDataPlan_id = this.dataDetails.variation_id;
      this.datapurchaseloading = true;
      this.amount = this.dataDetails.price;
      const payload = {
        phone: this.phone,
        variation_id: this.dataDetails.variation_id,
        network_id: this.network_id,
        user_id: this.currentUserData.id,
        request_id: Date.now(),
        note: `${this.dataDetails.name} Data Purchase`,
      };
      this.narration = `${this.dataDetails.name} Data Purchase`;
      axios
        .post(`https://vendor.middey.com/api/data`, payload, {
          headers: {
            Authorization: `Bearer ${this.currentUser.access_token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.datapurchaseloading = false;
          this.airtimeSuccess = true;
          this.step = 3;
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
          this.datapurchaseloading = false;
        });
    },
  },
};
</script>
<style scoped>
.card {
  @apply bg-[#E6F5FE] rounded-lg flex flex-col justify-center items-center p-4 space-y-3;
}

.card_price {
  background: #ffffff;
  /* Elevation 1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s linear;
}
</style>
