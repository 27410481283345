<!-- eslint-disable -->
<template>
  <div>
    <DashboardComp />
    <!-- <alert v-if="currentUserData.user_kyc_level == '1'" /> -->
    <!-- <InfoBox  /> -->
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters } from "vuex";

import Alert from "../../components/alert.vue";
import InfoBox from "../../components/InfoBox.vue";
import DashboardComp from "../../components/DashboardComp.vue";

export default {
  name: "Dashboard",
  components: { DashboardComp, Alert, InfoBox },

  watch: {
    checkActivity() {
      if (this.checkActivity) {
        this.logout();
        this.$swal("Timeout, Your session has expired.");
      }
    },
  },
  computed: {
    checkActivity() {
      return this.$store.state.idleVue.isIdle;
    },
    ...mapGetters(["currentUserData", "currentUser"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
      localStorage.clear();
    },
  },
};
</script>

<style></style>
