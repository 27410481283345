<template>
  <div><utilities /></div>
</template>

<script>
import Utilities from "../../components/Utilities.vue";

export default {
  components: { Utilities },
};
</script>

<style></style>
