<!-- eslint-disable -->

<template>
  <div class="w-full grid place-content-center">
    <div
      style="
        background-image: url('/Receipt.png');
        background-size: contain;
        background-repeat: no-repeat;
        height: 1000px;
      "
      class="p-4 w-[100vw] sm:w-[400px] mx-auto"
    >
      <div class="pt-12 md:pt-14 text-center">
        <p class="text-3xl font-medium">
          {{ result.txdata?.amount_formatted_disp || "loading..." }}
        </p>
        <p class="text-xs mt-3">
          Middey -
          {{ currentUserData.account_no_ng_default.substring(0, 3) }}xxxxxx{{
            currentUserData.account_no_ng_default.substring(7, 11)
          }}
        </p>
      </div>

      <div class="text-center mt-8 font-semibold">
        Transaction Reciept
      </div>

      <div class="pt-[20px]">
        <div class="flex flex-col gap-5 font-medium">
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Transaction Date:</p>
            </div>
            <div class="col-span-6 text-[12px] md:text-sm flex justify-end">
              {{ getDate("LLL", result.txdata?.post_date) || "loading.." }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Transaction Ref:</p>
            </div>
            <div class="col-span-6 text-xs text-right">
              ID_{{ result.txdata?.transaction_id || "loading..." }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Transaction Type:</p>
            </div>
            <div
              class="col-span-6 text-sm flex justify-end"
              :class="
                result.txdata?.txn_type === 'DEBIT' ? 'text-red-500' : 'text-green-500'
              "
            >
              {{ result.txdata?.txn_type || "loading..." }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800 capitalize">{{result.txdata?.txn_type }} Account:</p>
            </div>
            <div class="col-span-6 text-sm flex justify-end">
              {{
                currentUserData.account_no_ng_default.substring(0, 3)
              }}xxxxxx{{
                currentUserData.account_no_ng_default.substring(7, 11)
              }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Credit Account:</p>
            </div>
            <div class="col-span-6 text-[12px] truncate flex justify-end">
              {{ result.receiverAcctNumber }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Beneficial Name:</p>
            </div>
            <div class="col-span-6 text-[12px] truncate flex justify-end">
              {{ result.receiverName }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Bank:</p>
            </div>
            <div class="col-span-6 text-[12px] truncate flex justify-end">
              {{ result.receiverBankName }}
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Narration:</p>
            </div>
            <div class="col-span-6 text-xs md:text-sm truncate overflow-x-auto">
              {{ result.txdata?.note || "loading..." }}
            </div>
          </div>
         
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <p class="text-sm text-blue-800">Status:</p>
            </div>
            <div class="col-span-6 text-sm flex justify-end">{{ result.status }}</div>
          </div>
          <div class=" md:mt-[132px] mt-32">
          
            <div class="col-span-6 text-sm flex justify-end">{{ result.message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
import { digitFormatter } from "@/Utils/helper_function";
import axios from "@/Utils/axios.config.js";
import axiosInstance from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "DownloadableReciept",
 
  
  props: ["result"],


  computed: {
    ...mapGetters(["currentUserData", "currentUser"]),

    txId() {
      return +this.$route.params.id;
    },
  },

  methods: {
    digitFormatter(input) {
      return digitFormatter(input);
    },
    getDate(Format, date) {
      return moment(date).format(Format);
    },
  
  },
};
</script>
<!-- eslint-disable -->

<style scoped>
.topMargin {
  padding-top: 130px;
}
.padding {
  padding-top: 20px;
}
.padding2 {
  padding-top: 40px;
  color: red;
}
</style>
