<!-- eslint-disable -->

<template>
  <div class="flex justify-center w-full pt-16 pb-4">
    <div class="z-[2] let">
      <div class="sm:w-[500px] bg-[#fff] pt-4 pb-8 overflow-y-auto">
        <div class="" v-if="step === 0">
          <div class="bg-[#E4E4E7] px-5 text-sm md:text-base py-1 mb-4">
            Buy Airtime and Data Bundle
          </div>
          <div class="grid grid-cols-3 gap-4 px-4">
            <router-link to="/utility/airtime" class="card">
              <img src="@/assets/Svg/Utility/airtime.svg" alt="" />
              <h1 class="md:text-base text-xs">Airtime</h1>
            </router-link>
            <router-link to="/utility/data" class="card">
              <img src="@/assets/Svg/Utility/data.svg" alt="" />
              <h1 class="md:text-base text-xs">Data</h1>
            </router-link>
          </div>

          <div class="bg-[#E4E4E7] px-5 text-sm md:text-base py-1 my-4">
            Cable Tv (Coming Soon)
          </div>

          <div class="grid grid-cols-3 gap-4 px-4">
            <div class="card" v-for="(item, key) in cable" :key="key">
              <img
                :src="require(`@/assets/Svg/Utility/${item.logo}.png`)"
                alt=""
              />
              <h1 class="md:text-base text-xs">{{ item.name }}</h1>
            </div>
          </div>
          <div class="bg-[#E4E4E7] px-5 text-sm md:text-base py-1 my-4">
            Electricity (Coming Soon)
          </div>

          <div class="grid grid-cols-3 gap-4 px-4">
            <div
              class="card"
              v-for="(item, key) in electtricityBills"
              :key="key"
            >
              <img
                :src="require(`@/assets/Svg/Utility/${item.logo}.png`)"
                alt=""
              />
              <h1 class="md:text-base text-xs">{{ item.name }}</h1>
            </div>
          </div>
        </div>

        <SuccessAirtime
          v-if="step === 3"
          :amount="amount"
          :formatedAmount="amount"
          :phonenumber="phone"
          :narration="narration"
        />
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import axios from "axios";
import numeral from "numeral";
import { mapGetters } from "vuex";
import ActionLoader from "./ActionLoader.vue";
import SuccessAirtime from "./SucessAirtime.vue";

export default {
  components: { ActionLoader, SuccessAirtime },
  name: "BankList",
  props: [],
  data() {
    return {
      searchParam: "",
      isDropdown: false,
      selectedNetwork: null,
      dataDetails: null,
      networks: [
        {
          network_id: "mtn",
          name: "MTN",
          image:
            "https://i.pinimg.com/474x/ca/72/9b/ca729b8adbaa8e96f99f1cd0d8a64a8c.jpg",
        },
        {
          network_id: "glo",
          name: "GLO",
          image:
            "https://www.styzic.com/wp-content/uploads/2017/08/GLO-NIGERIA.jpg",
        },
        {
          network_id: "airtel",
          name: "Airtel",
          image:
            "https://thewhistler.ng/wp-content/uploads/2019/08/Airtel-logo-1.jpg",
        },
        {
          network_id: "etisalat",
          name: "Etisalat",
          image:
            "https://www.seekpng.com/png/detail/344-3443327_9mobile-mtn-glo-airtel-and-9mobile.png",
        },
      ],
      electtricityBills: [
        {
          name: "AEDC",
          logo: "aedc",
        },
        {
          name: "EKEDC",
          logo: "ekedc",
        },
        {
          name: "IBEDC",
          logo: "ibedc",
        },
        {
          name: "IKEDC",
          logo: "ikedc",
        },
        {
          name: "JED",
          logo: "jed",
        },
        {
          name: "KAEDCO",
          logo: "kaedco",
        },
        {
          name: "KEDCO",
          logo: "kedco",
        },
        {
          name: "PHED",
          logo: "phed",
        },
      ],
      cable: [
        {
          name: "DSTV",
          logo: "dstv",
        },
        {
          name: "Go TV",
          logo: "gotv",
        },
        {
          name: "Star Times",
          logo: "startimes",
        },
      ],

      step: 0,
      showBalance: false,
      option: "data",
      dataList: [],
      variation_id: "",
      network_id: "",
      phone: "",
      amount: 0,
      acct_balance: 0,
      loading: false,
      isConfirmed: {
        airtime: false,
        data: false,
      },
      airtimeSuccess: false,
      dataloading: false,
      airtimeloading: false,
      datapurchaseloading: false,
      narration: "",
      confirm_narration: "",
      isError: false,
    };
  },

  computed: {
    ...mapGetters(["currentUserData", "currentUser"]),
    isAirtimeError() {
      if (this.phone.length < 11 || this.amount == 0 || this.network_id == "") {
        return true;
      } else return false;
    },
    isDataError() {
      if (this.phone.length < 11 || this.network_id == "") {
        return true;
      } else return false;
    },

    dataListOutput() {
      if (this.network_id === "mtn") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("mtn")
        );
      }
      if (this.network_id === "glo") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("glo")
        );
      }
      if (this.network_id === "airtel") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("airtel")
        );
      }
      if (this.network_id === "etisalat") {
        return this.dataList.filter((item) =>
          item.network_id.toLowerCase().includes("etisalat")
        );
      }
    },
  },
  mounted() {
    this.getBalance();
    this.getAirtimeList();
    this.getDataList();
  },
  methods: {
    closeModal() {
      this.isConfirmed.data = false;
      this.isConfirmed.airtime = false;
    },
    openModal(tab) {
      if (tab == "data") {
        this.isConfirmed.data = true;
        this.confirm_narration = `${this.phone}/${this.dataDetails.name}/`;
      } else {
        this.isConfirmed.airtime = true;
        this.confirm_narration = `${this.phone}/${this.amount}/${this.network_id}`;
      }
    },
    handleDataPurchase(item) {
      this.dataDetails = item;
      this.openModal("data");
    },
    handleTopUpPurchase() {
      if (this.isConfirmed.data) {
        this.buyData();
      } else {
        this.buyAirtime();
      }
    },
    handleNetworkSelection(item) {
      this.selectedNetwork = item;
      this.network_id = item.network_id;
      this.isDropdown = !this.isDropdown;
    },
    getSearchParam(e) {
      this.searchParam = e.target.value;
    },
    getBalance() {
      this.loading = true;
      axios
        .get(
          `https://backend.middey.com/wp-json/rimplenet/v1/user-wallet-balance?user_id=${this.currentUserData.id}&wallet_id=ngn`
        )
        .then((response) => {
          this.acct_balance = response.data.data.wallet_balance_formatted;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getAirtimeList() {
      this.loading = true;
      axios
        .get(`https://vendor.middey.com/api/airtime`)
        .then((response) => {
          console.log(response);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    buyAirtime() {
      if (this.isAirtimeError) {
        this.$toast.error("Incomplete Form");
        return;
      }
      if (numeral(this.acct_balance)._value < this.amount) {
        this.$toast.error("Insufficient balance");
        return;
      }
      this.isConfirmed.airtime = false;
      this.airtimeloading = true;
      const payload = {
        phone: this.phone,
        amount: this.amount,
        network_id: this.network_id,
        user_id: this.currentUserData.id,
        request_id: Date.now(),
        note: `${this.phone}/${this.network_id} Airtime Purchase`,
      };
      this.narration = `${this.phone}/${this.network_id} Airtime Purchase`;

      axios
        .post(`https://vendor.middey.com/api/airtime`, payload, {
          headers: {
            Authorization: `Bearer ${this.currentUser.access_token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.airtimeloading = false;
          this.airtimeSuccess = true;
          this.step = 3;
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
          this.airtimeloading = false;
        });
    },
    getDataList() {
      this.dataloading = true;
      axios
        .get(`https://vendor.middey.com/api/data`)
        .then((response) => {
          console.log(response);
          this.dataList = response.data.data;
          this.dataloading = false;
        })
        .catch((err) => {
          this.dataloading = false;
        });
    },
    buyData() {
      if (numeral(this.acct_balance)._value < this.dataDetails.price) {
        this.$toast.error("Insufficient balance");
        return;
      }
      this.isConfirmed.data = false;
      this.chooseDataPlan_id = this.dataDetails.variation_id;
      this.datapurchaseloading = true;
      this.amount = this.dataDetails.price;
      const payload = {
        phone: this.phone,
        variation_id: this.dataDetails.variation_id,
        network_id: this.network_id,
        user_id: this.currentUserData.id,
        request_id: Date.now(),
        note: `${this.dataDetails.name} Data Purchase`,
      };
      this.narration = `${this.dataDetails.name} Data Purchase`;
      axios
        .post(`https://vendor.middey.com/api/data`, payload, {
          headers: {
            Authorization: `Bearer ${this.currentUser.access_token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.datapurchaseloading = false;
          this.airtimeSuccess = true;
          this.step = 3;
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
          this.datapurchaseloading = false;
        });
    },
  },
};
</script>
<style scoped>
.card {
  @apply bg-[#E6F5FE] rounded-lg flex flex-col justify-center items-center p-4 space-y-3;
}

.card_price {
  background: #ffffff;
  /* Elevation 1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s linear;
}
</style>
