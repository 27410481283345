<!-- eslint-disable -->
<template>
  <div>
    <div class="my-4 grid place-content-center py-10">
      <img src="../assets/Svg/kycdone.svg" alt="" class="mx-auto w-32 h-32" />
      <div class="text-center">
        <div class="text-2xl font-bold mb-4">Congratulations</div>
        <p class="font-medium">
          Review in progress... your account is going to be verify within
          48hrs.
        </p>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
/* eslint-disable */
export default {
  name: "stage2final",
  data() {
    return {};
  },
};
</script>

<style></style>
