var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"let swipeIn"},[_c('div',{staticClass:"w-full h-screen overflow-y-auto py-16 px-4"},[_c('div',{staticClass:"bg-white rounded-lg p-4 mt-5"},[_c('div',{staticClass:"flex flex-col space-y-2 divide-y"},[_c('div',{staticClass:"flex flex-col space-y-2"},[_vm._m(0),_c('div',{staticClass:"text-gray-500"},[_c('span',{},[_vm._v(" "+_vm._s(_vm.currentUserData.first_name)+" "+_vm._s(_vm.currentUserData.last_name)+" ")])])]),_c('div',{staticClass:"flex flex-col space-y-2"},[_vm._m(1),_c('div',{staticClass:"text-gray-500"},[_c('span',{staticClass:"f"},[_vm._v(" "+_vm._s(_vm.currentUserData.username)+" ")])])]),_c('div',{staticClass:"flex justify-between items-center py-2"},[_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"font-bold flex space-x-2"},[_c('div',[_vm._v("Email")]),(
                  _vm.currentUserData.nll_user_email_address_verified == 'yes'
                )?_c('div',{staticClass:"verified"},[_vm._v(" Verified ")]):_c('div',{staticClass:"not-verified"},[_vm._v("Not verified")])]),_c('div',{staticClass:"text-gray-500"},[_vm._v(_vm._s(_vm.currentUserData.user_email))])]),(_vm.currentUserData.nll_user_email_address_verified !== 'yes')?_c('button',{staticClass:"bg-blue-500 text-white md:text-base text-xs rounded py-2 px-3",class:_vm.loadingVerifyEmail ? 'cursor-not-allowed' : '',attrs:{"disabled":_vm.loadingVerifyEmail},on:{"click":_vm.verifyUserEmail}},[(_vm.loadingVerifyEmail)?_c('ActionLoader1'):_c('div',{staticClass:"font-medium"},[_vm._v("Verify")])],1):_vm._e()]),_c('div',{staticClass:"flex justify-between items-center py-2"},[_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"font-bold flex space-x-2"},[_c('div',[_vm._v("BVN")]),(_vm.kycLevel)?_c('div',{staticClass:"verified"},[_vm._v(" Verified ")]):_c('div',{staticClass:"not-verified"},[_vm._v("Not verified")])]),_c('div',{staticClass:"text-gray-500"},[_vm._v("XXXXXXXXX")])]),(!_vm.kycLevel)?_c('router-link',{staticClass:"bg-blue-500 text-white md:text-base text-xs rounded py-2 px-3",attrs:{"to":"/kyc"}},[_c('div',{staticClass:"font-medium"},[_vm._v("Verify")])]):_vm._e()],1),_c('div',{staticClass:"flex justify-between items-center py-2"},[_vm._m(2),_c('button',{staticClass:"bg-blue-500 text-white md:text-base text-xs rounded py-2 px-3",on:{"click":_vm.requestChangePasswordToken}},[(_vm.loadingReset)?_c('ActionLoader'):_c('div',{staticClass:"font-medium"},[_vm._v("Request")])],1)]),(
            _vm.currentUserData.nll_user_email_address_verified &&
            _vm.currentUserData.phone_verified &&
            !_vm.currentUserData.bank_name_ng_default
          )?_c('div',{staticClass:"mt-4 text-xs md:text-base py-2 space-y-2"},[_c('p',{},[_vm._v(" Click the below button to update your KYC and procees settlement account details ")]),_c('router-link',{staticClass:"bg-blue-500 text-white md:text-base text-xs rounded mt-1 py-2 px-3",attrs:{"to":"/kyc"}},[_vm._v(" Continue ")])],1):_vm._e(),_c('div',{staticClass:"flex justify-between items-center py-2"},[_vm._m(3),_c('router-link',{staticClass:"bg-red-500 text-white md:text-base text-xs rounded py-2 px-3",attrs:{"to":"/deactivate-account"}},[_c('div',{staticClass:"font-medium"},[_vm._v("Deactivate")])])],1)])])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPhoneOTP),expression:"showPhoneOTP"}],staticClass:"px-10 flex justify-center cursor-pointer items-center overlay transform transition duration-300 bg-red-500",class:_vm.showPhoneOTP
          ? ' transform  translate-x-0'
          : ' transform  - translate-x-[1000px]',on:{"click":function($event){_vm.showPhoneOTP = !_vm.showPhoneOTP}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPhoneOTP),expression:"showPhoneOTP"}],staticClass:"z-10 booknow transform transition duration-300",class:_vm.showPhoneOTP ? ' translate-x-0' : '- translate-x-[1000px]'},[_c('div',{staticClass:"rounded-md bg-white px-4 py-5"},[_c('div',{staticClass:"flex justify-end mb-b"},[_c('div',{on:{"click":function($event){_vm.showPhoneOTP = !_vm.showPhoneOTP}}},[_c('i',{staticClass:"fa-solid text-red-500 fa-close"})])]),_c('label',{staticClass:"font-medium mb-2"},[_vm._v("Phone Number")]),_c('div',{staticClass:"my-2 rounded flex border items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phone_number),expression:"phone_number",modifiers:{"trim":true}}],staticClass:"bg-transparent border-none outline-none flex-1 p-3",attrs:{"type":"number","maxlength":"11","name":"tel"},domProps:{"value":(_vm.phone_number)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updatePhoneAndVerify.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.phone_number=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',[(!_vm.$v.phone_number.required && _vm.$v.phone_number.$dirty)?_c('small',{staticClass:"text-red-500"},[_vm._v(" Phone Number is required! ")]):_vm._e()]),_c('button',{staticClass:"bg-blue-500 flex justify-center text-white md:text-base text-xs rounded py-2 px-3 w-full",class:_vm.loading ? 'cursor-not-allowed' : '',attrs:{"disabled":_vm.loading},on:{"click":_vm.updatePhoneAndVerify}},[(_vm.loading)?_c('ActionLoader3'):_c('div',{staticClass:"font-medium"},[_vm._v("Verify")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-bold space-x-2"},[_c('div',[_vm._v("Profile Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-bold space-x-2"},[_c('div',[_vm._v("UserName")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"font-bold space-x-2"},[_c('div',[_vm._v("Change Password")])]),_c('div',{staticClass:"text-gray-500"},[_vm._v("Request for a change of password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"font-bold flex space-x-2"},[_c('div',[_vm._v("Deactivate Account")])]),_c('div',{staticClass:"text-gray-500"},[_vm._v(" Do you wish to "),_c('span',{staticClass:"text-red-500 font-bold"},[_vm._v("close")]),_vm._v(" your account? ")])])
}]

export { render, staticRenderFns }