<!-- eslint-disable -->
<template>
  <div class="">
    <div
      class="z-[100] bg-black/70 fixed inset-0 duration-[1s] ease-in-out transform grid place-content-center px-4"
      :class="!isAlertOpen ? 'translate-x-[100000px]' : 'translate-x-0'"
    >
      <div class="md:w-[600px] bg-white shadow-md rounded-xl">
        <div class="p-4 flex justify-between border-b">
          <div class="text-blue-500 font-bold">
            <img src="@/assets/Middey_Horizontal.png" alt="" class="w-24" />
          </div>
          <button @click="alertHandler">
            <img src="@/assets/Svg/close.svg" alt="" />
          </button>
        </div>

        <div class="p-6">
          <div class="text-center font-medium text-2xl py-6">Congratulations! 🎉</div>
          <p class="mb-8 mt-4 text-center">
            Woow... You can now upgrade your account to
            <router-link to="/kyc" class="text-blue-500"
              ><strong>Tier 2</strong></router-link
            >
            , which enables you to send above ₦10, 000.
          </p>
          <div class="text-xs text-center">
            <span class="text-red-500 font-bold">Note:</span> Upgrade only
            available on Chrome, Firefox and Safari
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
import { mapGetters } from "vuex";
export default {
  name: "Alert",
  computed: {
    ...mapGetters(["isAlertOpen"]),
  },
  methods: {
    alertHandler() {
      this.$store.dispatch("setIsAlertOpen");
    },
  },
};
</script>
