<!-- eslint-disable -->
<template>
  <div class="">
    <div
      :class="isOpen ? ' translate-x-0' : ' -translate-x-[10000px]'"
      class="overlay px-10 flex justify-center cursor-pointer items-center transform"
    >
      <div class="z-[12]">
        <bank-list
          :banks="banks"
          v-if="isBankOpen"
          :handleClose="handleBankModal"
          :handleBankSelect="handleBankSelect"
        />
        <div
          class="w-screen h-screen overflow-y-auto sm:h-auto sm:w-[500px] rounded-md bg-white pt-12 pb-8"
          v-if="!isBankOpen && !isTransferSuccessful && !transferError"
        >
          <div class="flex justify-center items-center space-x-4 mb-3 pb-6">
            <div
              class="animate animate-pulse grid place-content-center w-10 h-10 text-white bg-[#0192ED] p-4 rounded-full"
            >
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </div>
            <h1 class="text-center font-bold">Send Fund</h1>
          </div>
          <div class="bg-[#E6F5FE] font-bold p-5 mb-3 flex justify-between">
            <h1>
              Balance:
              <span class="" v-if="showBalance">
                {{ acct_balance }}
              </span>
              <span class="" v-else>₦XXXX</span>
            </h1>

            <button
              class="text-blue-500 px-2 text-base md:text-xl font-medium icon cursor-pointer"
              @click="showBalance = !showBalance"
            >
              <span v-if="showBalance"><i class="fas fa-eye-slash"></i></span>
              <span v-else><i class="fas fa-eye"></i></span>
            </button>
          </div>
          <form @submit.prevent="confirmSendMoneyAction">
            <div class="space-y-4 px-4">
              <div class="">
                <label class="font-medium mb-2">Amount</label>
                <div class="rounded-lg flex border border-gray-500">
                  <div
                    class="rounded-l-lg bg-[#D9D9D9] grid place-content-center p-2"
                  >
                    <p class="text-gray-500 text-xl">₦</p>
                  </div>
                  <input
                    required
                    type="tel"
                    placeholder="amount"
                    v-model="amountValue"
                    class="bg-transparent w-full border-none outline-none flex-1 p-3 select-none"
                  />
                </div>
              </div>
              <div class="text-red-500 w-full text-xs md:text-base">
                <p class="">
                  {{ getHighestValueToSendOut }}
                </p>
                <p v-if="!$v.amount_for_transfer.minValue" class="">
                  Minimum transfer amount is NGN 1.
                </p>
              </div>
              <h5 class="text-right text-gray-500 text-sm font-medium mr-4">
                Fee:
                <span class="text-green-500" v-if="Charge == 'Free'"
                  >NGN {{ Charge }}</span
                >
                <span class="text-red-500" v-else> NGN {{ Charge }}</span>
              </h5>

              <div class="">
                <label class="font-medium mb-2"
                  >Recipient's Account Number</label
                >
                <div class="rounded-lg flex item-center border border-gray-500">
                  <input
                    type="number"
                    required
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="10"
                    @blur="getAccountOwner()"
                    @keyup="getAccountOwner()"
                    @change="getAccountOwner()"
                    placeholder="Receipient's account number"
                    class="bg-transparent w-full border-none outline-none flex-1 p-3 select-none"
                    v-model="acctNumber"
                  />
                  <div
                    v-if="isAcctAvail"
                    class="h-6 w-6 m-4 rounded-full border-4 border-t-[#0192ED] border-r-[#0192ED] border-b-[#ed323730] border-l-[#ed323730] animate-spin"
                  ></div>
                </div>
              </div>
              <div class="">
                <label class="font-medium mb-2">Recipient's Bank</label>
                <div
                  class="mb-1 rounded-lg flex item-center border border-gray-500"
                >
                  <input
                    required
                    type="text"
                    readonly
                    @click="handleBankModal"
                    placeholder="Bank Name"
                    v-model.trim="bankSelectedName"
                    class="bg-transparent w-full border-none outline-none flex-1 p-3 select-none"
                  />
                  <div
                    class="grid place-content-center p-2"
                    @click="handleBankModal"
                  >
                    <img src="@/assets/Svg/arrow.svg" alt="" />
                  </div>
                </div>
              </div>
              <div>
                <label class="font-medium mb-2">Account Name</label>

                <div
                  class="mb-2 rounded-lg border border-red-500 text-red-400 flex item-center p-3"
                  v-if="errorMessage"
                >
                  {{ errorMessage }}
                </div>
                <div
                  class="mb-2 rounded-lg font-bold flex item-center border border-gray-500 p-3"
                  v-else
                >
                  {{ accountDetails.account_name }}
                </div>
              </div>

              <div class="">
                <label class="font-medium mb-2">Narration</label>
                <div
                  class="mb-2 rounded-lg flex item-center border border-gray-500 p-3"
                >
                  <input
                    type="text"
                    placeholder="Narration"
                    class="truncate outline-none w-full bg-transparent"
                    v-model="narration"
                  />
                </div>
              </div>
              <div
                class="mt-4 flex justify-between items-center w-full space-y-2"
              >
                <div
                  @click="handleClose"
                  class="border border-red-500 rounded py-3 px-4 text-[12px] font-medium text-red-500 w-max"
                >
                  Cancel
                </div>
                <button
                  class="flex justify-center text-white md:text-base text-sm rounded py-3 px-6 w-max"
                  :class="
                    !isInfoComplete
                      ? 'cursor-not-allowed bg-gray-500/50'
                      : 'bg-primary-100'
                  "
                  :disabled="loading || !isInfoComplete"
                >
                  <action-loader v-if="loading" />

                  <div v-else class="font-medium">Proceed</div>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          v-if="isTransferSuccessful"
          class="w-screen h-screen sm:w-[500px] rounded-md bg-white pt-12 pb-8"
        >
          <SuccessTransfer
            :amount="amountValue"
            :formatedAmount="amount_for_transfer"
            :account_name="accountDetails.account_name"
            :bank_name="bankSelectedName"
            :narration="narration"
            :account_no="acctNumber"
          />
        </div>
        <div
          v-if="transferError"
          class="w-screen h-screen sm:w-[500px] rounded-md bg-white pt-12 pb-8"
        >
          <error-transfer :errorMessage="transferErrorMessage" />
        </div>
      </div>
    </div>

    <div
      class="fixed z-[10] bg-black/30 inset-0 w-full grid place-content-center p-2"
      v-if="isConfirmed"
    >
      <div class="bg-white rounded-lg p-3 shadow w-[350px]">
        <div class="flex justify-end">
          <button @click="isConfirmed = false" class="">
            <img src="@/assets/Svg/close.svg" alt="" />
          </button>
        </div>
        <h1 class="text-center font-semibold">Confirm Details</h1>

        <div class="grid grid-cols-2 gap-4 mt-4 text-xs">
          <div class="text-right">Recipient Name</div>
          <div class="font-bold truncate">
            {{ accountDetails.account_name }}
          </div>
          <div class="text-right">Transaction Type</div>
          <div class="font-bold">Debit</div>
          <div class="text-right">Bank Name</div>
          <div class="font-bold">{{ bankSelectedName }}</div>
          <div class="text-right">Receiver Acct No</div>
          <div class="font-bold">{{ acctNumber }}</div>
          <div class="text-right">Transaction Date</div>
          <div class="font-bold">{{ dateFormatter(Date.now()) }}</div>
          <div class="text-right">Payment Amount</div>
          <div class="font-bold">
            ₦ {{ digitFormatter(amount_for_transfer) }}
          </div>
          <div class="text-right">Narration</div>
          <div class="font-bold">{{ narration || "Null" }}</div>
        </div>

        <div class="space-y-5 my-8">
          <button
            @click="handleSendMoney"
            class="text-white p-3 rounded-lg w-full bg-blue-500"
          >
            YES
          </button>
          <button
            @click="isConfirmed = false"
            class="text-red-500 p-3 rounded-lg w-full border border-red-500"
          >
            NO, CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    class="z-[999999] bg-black/70 fixed inset-0 duration-[1s] ease-in-out transform grid place-content-center px-4"
    :class="isOpen ? 'let swipeIn' : 'hidden'"
  >
    <div class="md:w-[600px] bg-white shadow-md rounded-xl">
      <div class="p-4 flex justify-between border-b">
        <div class="text-blue-500 font-bold">
          <img src="@/assets/Middey_Horizontal.png" alt="" class="w-24" />
        </div>
        <button @click="close">
          <img src="@/assets/Svg/close.svg" alt="" />
        </button>
      </div>

      <div class="p-6">
        <p class="mb-8 mt-4 text-center">
          Dear valued customer, Transfers and utility payment are temporarily
          not available at the moment, as our technical team are working
          tirelessly to restore the service, please check back at a later time.
          Thank You.
        </p>
      </div>
    </div>
  </div> -->
</template>
<!-- eslint-disable -->

<script>
import numeral from "numeral"; //Helps in Number Formatting, Convert string to number, currency formatting and lots  more
import BankList from "./BankList.vue";
import { digitFormatter } from "@/Utils/helper_function/";
import moment from "moment";

import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
import LvDropdown from "lightvue/dropdown";
import { mapGetters } from "vuex";
import PincodeInput from "vue-pincode-input";
import axios from "axios";
import ActionLoader from "./ActionLoader.vue";
import { refreshUser } from "@/Utils/refreshUser";
import SuccessTransfer from "./SuccessTransfer.vue";
import ErrorTransfer from "./ErrorTransfer.vue";
export default {
  props: ["isOpen", "close", "userBalance"],
  mixins: [validationMixin],
  validations: {
    amount_for_transfer: {
      required,
      minValue: minValue(1),
      // maxValue: maxValue(this.getHighestValueToSendOut()),
    },
  },

  data() {
    return {
      loading: false,
      isConfirmed: false,
      isBankOpen: false,
      showBalance: false,
      amount_for_transfer: "",
      formattedAmount: "",
      accountDetails: {
        account_name: "",
        account_number: "",
      },
      acct_balance: 0,
      errorMessage: "",
      getHighestValueToSendOutError: false,
      loadingPageData: false,
      isAcctAvail: false,
      acctNumber: "",
      narration: "",
      bankSelectedName: "",
      bankSelectedAcctNumber: "",
      selectedBank: null,
      banks: [],
      isTransferSuccessful: false,
      transferError: false,
      transferErrorMessage: "",
    };
  },
  watch: {
    formattedAmount() {
      var realValue = numeral(this.formattedAmount);
      this.amount_for_transfer = realValue._value;
    },
  },
  computed: {
    ...mapGetters(["currentUserData", "currentUser"]),
    amountValue: {
      get() {
        return this.formattedAmount;
      },
      set(value) {
        //Amount Formatter
        this.formattedAmount = numeral(value).format("0,0[.]00");
      },
    },
    isInfoComplete() {
      if (
        this.bankSelectedName === "" ||
        this.accountDetails.account_name === "" ||
        this.amount_for_transfer === "" ||
        this.getHighestValueToSendOutError
      ) {
        return false;
      } else return true;
    },
    Charge() {
      if (+this.currentUserData.no_of_debits_for_this_month >= 3) {
        if (
          this.amount_for_transfer >= 1 &&
          this.amount_for_transfer <= 999.99
        ) {
          return "20.00";
        }
        if (
          this.amount_for_transfer >= 1000 &&
          this.amount_for_transfer <= 49999.99
        ) {
          return "35.00";
        }
        if (this.amount_for_transfer > 49999.99) {
          return "65.00";
        }
      } else return "10";
    },
    getHighestValueToSendOut() {
      let getuserBalance = numeral(this.userBalance)._value;
      let transferCharge = numeral(this.Charge)._value;
      console.log(this.amount_for_transfer + transferCharge);
      const kycLevel = parseInt(this.currentUserData.user_kyc_level);
      if (this.amount_for_transfer + transferCharge > getuserBalance) {
        this.getHighestValueToSendOutError = true;
        return "Insufficient funds to transfer";
      } else this.getHighestValueToSendOutError = false;
      if (kycLevel === 1 && this.amount_for_transfer > 10000) {
        this.getHighestValueToSendOutError = true;
        return "Level 1 users can only transfer NGN 10,000 or lower.";
      } else this.getHighestValueToSendOutError = false;

      if (kycLevel === 2 && this.amount_for_transfer > 100000) {
        this.getHighestValueToSendOutError = true;
        return "Level 2 users can only transfer NGN 100,000 or lower.";
      } else this.getHighestValueToSendOutError = false;
    },
  },
  mounted() {
    console.log(this.isInfoComplete);
    //get new user changes at every page load
    refreshUser(
      this.currentUserData.id,
      this.currentUser.access_token,
      this.$store
    );
    this.getBanks();
    this.getBalance();
    this.transferChargeChecker(
      this.currentUserData.no_of_debits_for_this_month
    );
  },
  methods: {
    handleSendMoney() {
      this.isConfirmed = false;
      this.sendMoney();
    },
    getBalance() {
      this.loading = true;
      axios
        .get(
          `https://backend.middey.com/wp-json/rimplenet/v1/user-wallet-balance?user_id=${this.currentUserData.id}&wallet_id=ngn`
        )
        .then((response) => {
          this.acct_balance = response.data.data.wallet_balance_formatted;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleClose() {
      this.amount_for_transfer = "";
      this.bankSelectedName = "";
      this.acctNumber = "";

      this.accountDetails.account_name = "";
      this.narration == "";
      this.formattedAmount == "";
      this.close();
    },
    handleBankModal() {
      this.isBankOpen = !this.isBankOpen;
    },
    handleBankSelect(selectedBank) {
      this.selectedBank = selectedBank;
      this.bankSelectedName = selectedBank.name;
      this.getAccountOwner();
      this.handleBankModal();
    },
    confirmSendMoneyAction() {
      this.isConfirmed = true;
    },
    dateFormatter(date) {
      return moment(date).format("LLL");
    },
    sendMoney() {
      this.loading = true;
      axios
        .post(
          "https://middleware.middey.com/api/transfer",
          {
            user_id: this.currentUserData.id,
            request_id: `bank_transfer_${Date.now()}`,
            amount: +this.amount_for_transfer,
            bank_code: this.selectedBank.code.toString(),
            bank_name: this.selectedBank.name.toString(),
            account_number: this.accountDetails.account_number.toString(),
            account_name: this.accountDetails.account_name.toString(),
            note: `${this.narration}/FRM ${this.currentUserData.account_name_ng_default}`,
          },
          {
            headers: {
              Authorization: `Bearer ${this.currentUser.access_token}`,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          this.isConfirmed = false;
          this.isTransferSuccessful = true;
          refreshUser(
            this.currentUserData.id,
            this.currentUser.access_token,
            this.$store
          );
          // this.$swal("Successful", `${response.data.message}`, "success");
        })
        .catch((err) => {
          this.loading = false;
          this.isConfirmed = false;

          console.log(err);
          this.transferError = true;
          this.transferErrorMessage = err.response.data.message;
        });
    },
    getBanks() {
      this.loadingPageData = true;
      axios
        .get("https://middleware.middey.com/api/banks")
        .then((response) => {
          this.banks = response.data.data;
          this.loadingPageData = false;
        })
        .catch((err) => {
          this.loadingPageData = false;
        });
    },
    handleBankSelection() {
      if (this.bankSelected !== "") {
        this.selectedBank = this.banks.find(
          (bank) => bank.name.toLowerCase() == this.bankSelected.toLowerCase()
        );
      }
    },
    getAccountOwner(e) {
      if (
        (this.acctNumber.length > 9 || this.acctNumber.length == 10) &&
        this.selectedBank !== null
      ) {
        console.log(this.selectedBank);
        this.isAcctAvail = true;
        axios
          .post("https://middleware.middey.com/api/banks", {
            account_number: this.acctNumber,
            bank_code: this.selectedBank.code,
          })
          .then((response) => {
            this.isAcctAvail = false;
            if (response.data.status == "error") {
              this.errorMessage = "Beneficiary account not found";
              this.accountDetails.account_name = "";
            } else {
              this.isAcctAvail = false;
              this.errorMessage = "";
              this.accountDetails.account_name =
                response.data.data.account_name;
              this.accountDetails.account_number =
                response.data.data.account_number;
            }
          })
          .catch((err) => {
            this.errorMessage = "Beneficiary account not found";

            this.isAcctAvail = false;
          });
      } else {
        this.accountDetails.account_name = "";
        this.errorMessage = "";
      }
    },
    digitFormatter(balance) {
      return digitFormatter(balance);
    },
    transferChargeChecker(count) {
      if (!count) {
        return 0;
      } else if (count == "1") {
        return 1;
      } else if (count == "2") {
        return 2;
      } else if (count == "3") {
        return 3;
      }
    },
  },
  components: {
    PincodeInput,
    LvDropdown,
    ActionLoader,
    SuccessTransfer,
    BankList,
    ErrorTransfer,
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s linear;
}
.booknow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
}
</style>
